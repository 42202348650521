import axios from "axios";
import axiosRetry from "axios-retry";
import { HeadFC } from "gatsby";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { trackPromise } from "react-promise-tracker";
import CookiesConsentNotice from "../../components/cookies-consent/cookies-consent";
import ErrorMessage from "../../components/error-message/error-message";
import Footer from "../../components/footer/footer";
import Navigation from "../../components/navigation/navigation";
import FullScreenPromiseTracker from "../../components/promise-tracker/full-screen-tracker";
import SEO from "../../components/seo/seo";
import StylesComponent from "../../components/styles/styles-component";

// CSS
import "../../sass/page-specific/webform-styles.scss";

type AuthState = 
   | "pending"
   | "authenticated"
   | "not_found"
   | "expired"
   | "completed"

interface EventDetails {
   first_name: string,
   last_name: string,
   phone_number: string,
   number_of_guests: number,
   event_date: string,
   booking_reference: string,
   location: string
}

interface GuestInformation {
   first_name: string,
   last_name: string,
   requirements: string,
   email_address: string,
   phone_number: string,
   primary: boolean
}

interface GuestErrors {
   first_name: boolean,
   last_name: boolean,
   email_address: boolean,
   email_address_same: boolean,
   phone_number: boolean,
   phone_number_same: boolean
}

axiosRetry(axios, {
   retries: 3,
   retryDelay: (retryCount) => {
      console.log(`Error - retry attempt: ${retryCount}`)
      return retryCount * 500
   }
})

const DietaryRequirementsFrom: React.FC = (): JSX.Element => {
   const [authState, setAuthState] = useState<AuthState>("pending")
   const searchParams = new URLSearchParams(typeof window === "undefined" ? {} : window.location.search)
   
   const event_id = searchParams.get("eventId")
   const email_address = searchParams.get("email")

   const [eventDetails, setEventDetails] = useState<EventDetails>({
      first_name: "",
      last_name: "",
      phone_number: "",
      number_of_guests: 0,
      event_date: "",
      booking_reference: "",
      location: ""
   })

   const [guestInformation, setGuestInformation] = useState<GuestInformation[]>([{
      first_name: "Elliot",
      last_name: "",
      phone_number: "",
      email_address: "",
      requirements: "",
      primary: true
   }])

   const [guestErrors, setGuestErrors] = useState<GuestErrors[]>([])
   const [error, setError] = useState<boolean>(false)
   const [errorMessage, setErrorMessage] = useState<string>("")

   const getEventDetails = async (): Promise<void> => {
      if(event_id === null || event_id === undefined || event_id === "" || email_address === null || email_address === undefined || email_address === "") {
         setAuthState("not_found")
         return
      }

      return await new Promise<void>(async (resolve) => {
         await axios({
            method: "GET",
            url: `https://api.prestige-vip.com/public/webforms/dietary-requirements/event/${event_id}`,
            params: {
               email_address: email_address
            }
         })
         .then( async (value) => {
            const response = value.data;

            if(response.success === true) {
               setEventDetails(response.data)

               // Set guest information
               let guests: GuestInformation[] = []
               let errors: GuestErrors[]= []

               for (let i = 0; i < response.data.number_of_guests - 1; i++) {
                  guests.push({
                     first_name: "",
                     last_name: "",
                     email_address: "",
                     phone_number: "",
                     requirements: "",
                     primary: false
                  })

                  errors.push({
                     first_name: false,
                     last_name: false,
                     email_address: false,
                     email_address_same: false,
                     phone_number: false,
                     phone_number_same: false
                  })
               }

               setGuestInformation([
                  {
                     first_name: response.data.first_name,
                     last_name: response.data.last_name,
                     email_address: email_address,
                     phone_number: response.data.phone_number,
                     requirements: "",
                     primary: true
                  },
                  ...guests,
               ])

               setGuestErrors([
                  {
                     first_name: false,
                     last_name: false,
                     email_address: false,
                     email_address_same: false,
                     phone_number: false,
                     phone_number_same: false
                  },
                  ...errors
               ])

               setAuthState("authenticated")

               resolve()
            } else {
               if(response.reason === "No invite found") {
                  setAuthState("not_found")

                  resolve()
               } else if (response.reason === "Invite expired") {
                  setAuthState("expired")
                  resolve()
               } else {
                  setAuthState("not_found")
                  resolve()
               }
            }
         })
      })
   }

   useEffect(() => {
      if(authState === "pending") {
         getEventDetails();
      }
   }, [])

   const handleUpdateGuest = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number): void => {
      const { value, name } = event.target;

      const currrentState = [...guestInformation]
      currrentState[index] = {
         ...currrentState[index],
         [name]: value
      }
      
      setGuestInformation([ ...currrentState ])

      const currentErrors = [...guestErrors]
      currentErrors[index] = {
         ...currentErrors[index],
         [name]: false,
         email_address_same: name === "email_address" ? false : currentErrors[index].email_address_same,
         phone_number_same: name === "phone_number" ? false : currentErrors[index].phone_number_same
      }

      setGuestErrors([...currentErrors])
   }

   const handleDataValidation = (): boolean => {
      let total_errors: number = 0;
      let errors_array: GuestErrors[] = [...guestErrors]

      let used_email_addresses: string[] = [];
      let used_phone_numbers: string[] = [];

      for (let i = 0; i < guestInformation.length; i++) {
         const guest = guestInformation[i];

         console.log(guest.first_name, used_phone_numbers)

         const failed_keys = Object.keys(guest).filter(key => {
            if(key === "first_name" || key === "last_name") {
               if(guest[key] === "") {
                  return true
               }
            } else if (key === "email_address") {
               if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/.test(guest[key]) === false) {
                  return true
               } else {
                  if(used_email_addresses.includes(guest[key]) === true) {
                     errors_array[i] = {
                        ...errors_array[i],
                        email_address_same: true
                     }
                     total_errors++
                  } else {
                     used_email_addresses.push(guest[key])
                  }
               }
            } else if (key === "phone_number") {
               if(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(guest[key]) === false) {
                  return true
               } else {
                  if(used_phone_numbers.includes(guest[key]) === true) {
                     errors_array[i] = {
                        ...errors_array[i],
                        phone_number_same: true
                     }
                     total_errors++
                  } else {
                     used_phone_numbers.push(guest[key])
                  }
               }
            }
         })

         console.log(errors_array)

         if(failed_keys.length > 0) {
            failed_keys.forEach((key) => {
               total_errors++;

               errors_array[i] = {
                  ...errors_array[i],
                  [key]: true
               }
            })
         }
      }

      if(total_errors === 0) {
         return true
      } else {
         if(typeof window !== "undefined") {
            window.scrollTo({ top: 600, behavior: "smooth" })
         }

         setGuestErrors(errors_array)

         return false
      }
   }

   const handleSubmitForm = async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault();

      if(handleDataValidation() === true) {
         trackPromise(
            new Promise<void>( async (resolve) => {
               await axios({
                  method: "POST",
                  url: `https://api.prestige-vip.com/public/webforms/dietary-requirements/event/${event_id}`,
                  data: guestInformation
               })
               .then( async (value) => {
                  const response = value.data;

                  if(response.success === true) {
                     setAuthState("completed")
                  } else {
                     setError(true)
                     setErrorMessage(response.reason)
                  }

                  resolve()
               })
               .catch(() => {
                  setError(true)
                  setErrorMessage("Oops, there was a technical error, please try again")

                  resolve()
               })
            })
         , 'submit_form')
      }
   }

   const generateContent = (): JSX.Element => {
      switch (authState) {
         case "pending":
            return (
               <div className="event-hero-outer-container" style={{ backgroundImage: `url(/images/dietary-requirements.jpg)`}}>
                  <div className="event-hero-container-overlay">
                     <div className="event-hero-text-container container-width">
                        <ThreeDots
                           color="#FFF"
                           width={120}
                        />

                        <h3 style={{color: '#FFF', marginTop: 10}}>Loading...</h3>
                     </div>
                  </div>
               </div>
            )

         case "authenticated":
            return (
               <React.Fragment>
                  <FullScreenPromiseTracker
                     searchArea="submit_form"
                     message="Submitting requirements..."
                  />
                  <div className="event-hero-outer-container" style={{ backgroundImage: `url(/images/dietary-requirements.jpg)`}}>
                     <div className="event-hero-container-overlay">
                        <div className="event-hero-text-container container-width">
                           <h1>Dietary requirements</h1>
                           <p className="secondary-text">{eventDetails.location} | {new Date(eventDetails.event_date).toDateString()}</p>
                        </div>
                     </div>
                  </div>

                  <div className="standard-event-outer-container">
                     <div className="standard-event-inner-container container-width" style={{maxWidth: 900}}>
                        <h3 style={{color: '#003015'}}>Hi {eventDetails.first_name}, your event at {eventDetails.location} is in just <u>{Math.round((new Date(eventDetails.event_date).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24)} days</u></h3>
                        <p style={{marginTop: 0}}>It's time to provide your dietary requirements.</p>

                        <br/>
                        <br/>

                        {guestInformation.map((guest, i) => (
                           <div className={`guest-details-form-section ${guest.primary ? 'primary' : ''}`}>
                              <h3>{guest.primary ? 'Lead guest' : `Guest #${i}`}</h3>

                              <hr/>

                              <div className="form-two-columns">
                                 <span>
                                    <label className={`expanded-input-wrapper ${guest.primary ? 'dark-green' : 'plain-white'} ${guestErrors[i].first_name ? 'error' : ''}`} htmlFor={`first_name_${i}`}>
                                       <div className="expanded-input-content">
                                          <input
                                             id={`first_name_${i}`}
                                             className="expanded-input"
                                             placeholder="e.g. John"
                                             autoComplete="off"
                                             name="first_name"
                                             value={guestInformation[i].first_name}
                                             onChange={(e) => handleUpdateGuest(e, i)}
                                          />

                                          <label className="expanded-input-label" htmlFor={`first_name_${i}`}>First name*</label>
                                       </div>
                                    </label>

                                    {
                                       guestErrors[i].first_name ? (
                                          <ErrorMessage
                                             topSpacing={-5}
                                             message="Please enter guest's first name"
                                          />
                                       ) : null
                                    }
                                 </span>

                                 <span>
                                    <label className={`expanded-input-wrapper ${guest.primary ? 'dark-green' : 'plain-white'} ${guestErrors[i].last_name ? 'error' : ''}`} htmlFor={`last_name_${i}`}>
                                       <div className="expanded-input-content">
                                          <input
                                             id={`last_name_${i}`}
                                             className="expanded-input"
                                             placeholder="e.g. Smith"
                                             autoComplete="off"
                                             name="last_name"
                                             value={guestInformation[i].last_name}
                                             onChange={(e) => handleUpdateGuest(e, i)}
                                          />

                                          <label className="expanded-input-label" htmlFor={`last_name_${i}`}>Last name*</label>
                                       </div>
                                    </label>

                                    {
                                       guestErrors[i].last_name ? (
                                          <ErrorMessage
                                             topSpacing={-5}
                                             message="Please enter guest's last name"
                                          />
                                       ) : null
                                    }
                                 </span>
                              </div>

                              <span>
                                 <label className={`expanded-input-wrapper ${guest.primary ? 'dark-green' : 'plain-white'} ${guestErrors[i].email_address || guestErrors[i].email_address_same ? 'error' : ''}`} htmlFor={`email_address_${i}`}>
                                    <div className="expanded-input-content">
                                       <input
                                          id={`email_address_${i}`}
                                          className="expanded-input"
                                          placeholder="e.g. john.smith@gmail.com"
                                          autoComplete="off"
                                          name="email_address"
                                          value={guestInformation[i].email_address}
                                          onChange={(e) => handleUpdateGuest(e, i)}
                                       />

                                       <label className="expanded-input-label" htmlFor={`email_address_${i}`}>Email address*</label>
                                    </div>
                                 </label>

                                 {
                                    guestErrors[i].email_address ? (
                                       <ErrorMessage
                                          topSpacing={-5}
                                          message="Please enter a valid email address"
                                       />
                                    ) : null
                                 }


                                 {
                                    guestErrors[i].email_address_same ? (
                                       <ErrorMessage
                                          topSpacing={-5}
                                          message="This email address has already been used in this form"
                                       />
                                    ) : null
                                 }
                              </span>

                              <span>
                                 <label className={`expanded-input-wrapper ${guest.primary ? 'dark-green' : 'plain-white'} ${guestErrors[i].phone_number || guestErrors[i].phone_number_same ? 'error' : ''}`} htmlFor={`phone_number_${i}`}>
                                    <div className="expanded-input-content">
                                       <input
                                          id={`phone_number_${i}`}
                                          className="expanded-input"
                                          placeholder="e.g. 07412345678"
                                          autoComplete="off"
                                          name="phone_number"
                                          value={guestInformation[i].phone_number}
                                          onChange={(e) => handleUpdateGuest(e, i)}
                                       />

                                       <label className="expanded-input-label" htmlFor={`phone_number_${i}`}>Phone number*</label>
                                    </div>
                                 </label>

                                 {
                                    guestErrors[i].phone_number ? (
                                       <ErrorMessage
                                          topSpacing={-5}
                                          message="Please enter guest's phone number"
                                       />
                                    ) : null
                                 }

                                 {
                                    guestErrors[i].phone_number_same ? (
                                       <ErrorMessage
                                          topSpacing={-5}
                                          message="This phone number has already been used in this form"
                                       />
                                    ) : null
                                 }
                              </span>

                              <span>
                                 <label className={`expanded-input-wrapper ${guest.primary ? 'dark-green' : 'plain-white'}`} htmlFor={`requirements_${i}`}>
                                    <div className="expanded-input-content">
                                       <textarea
                                          id={`requirements_${i}`}
                                          className="expanded-input textarea"
                                          placeholder="Tell us any requirements you have..."
                                          autoComplete="off"
                                          name="requirements"
                                          value={guestInformation[i].requirements}
                                          onChange={(e) => handleUpdateGuest(e, i)}
                                       />

                                       <label className="expanded-input-label" htmlFor={`requirements_${i}`}>Dietary requirements</label>
                                    </div>
                                 </label>
                              </span>
                           </div>
                        ))}

                        {
                           error ? (
                              <ErrorMessage
                                 message={errorMessage}
                                 style={{justifyContent: "center"}}
                              />
                           ) : null
                        }

                        <br/>

                        <button
                           className="standard-button orange"
                           onClick={handleSubmitForm}
                        >Submit requirements</button>
                     </div>
                  </div>
               </React.Fragment>
            )

         case "not_found":
            return (
               <div className="event-hero-outer-container" style={{ backgroundImage: `url(/images/dietary-requirements.jpg)`}}>
                  <div className="event-hero-container-overlay">
                     <div className="event-hero-text-container container-width">
                        <h1>Page not found</h1>
                        <p className="secondary-text">Please check that you clicked directly on the email link</p>
                     </div>
                  </div>
               </div>
            )

         case "expired":
            return (
               <div className="event-hero-outer-container" style={{ backgroundImage: `url(/images/dietary-requirements.jpg)`}}>
                  <div className="event-hero-container-overlay">
                     <div className="event-hero-text-container container-width">
                        <h1>Linked expired</h1>
                        <p className="secondary-text">The event may have passed, or you've already filled in your requirements</p>
                     </div>
                  </div>
               </div>
            )

         case "completed":
            return (
               <div className="event-hero-outer-container" style={{ backgroundImage: `url(/images/dietary-requirements.jpg)`}}>
                  <div className="event-hero-container-overlay">
                     <div className="event-hero-text-container container-width">
                        <h1>Requirements submitted</h1>
                        <p className="secondary-text">Thank you for submitting your dietary requirements.</p>
                     </div>
                  </div>
               </div>
            )

         default:
            return (
               <div className="event-hero-outer-container" style={{ backgroundImage: `url(/images/dietary-requirements.jpg)`}}>
                  <div className="event-hero-container-overlay">
                     <div className="event-hero-text-container container-width">
                        <h1>Page not found</h1>
                        <p className="secondary-text">Please check that you clicked directly on the email link</p>
                     </div>
                  </div>
               </div>
            )
      }
   }
   
   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         {generateContent()}

         <Footer/>
      </React.Fragment>
   )
}

export default DietaryRequirementsFrom

export const Head: HeadFC = () => (
   <SEO
      title="Dietary requirements form | Prestige VIP"
      titleTemplate="%s"
      metaDescription="As your event with us approaches, it's now time to send us your dietary requirements."
      slug="/forms/dietary-requirements"
   />
)